import { React, useState, useRef } from 'react';
//import AnchorButton from '../components/AnchorButton';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import Payments from '../components/Payments';
import SplitSection from '../components/SplitSection';
import MessageOverlay from '../components/messageOverlay';
import Carousel from '../components/Carousel';
//import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import SvgCharts from "../svg/SvgCharts";
//import HeroImage from '../svg/HeroImage';
//import SvgCharts from '../svg/SvgCharts';
import bannerImage from '../assets/images/biometriclogin.jpg';
import PresentationVideo from '../assets/media/punch-in-presentation-video-h264.mp4';
import anyHardware from '../assets/images/any-hardware.jpg';
import rightSolution from '../assets/images/right-solution.jpg';
import saveCosts from '../assets/images/save-costs.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFighterJet, faHandPointRight, faBook, faEnvelope, faPhone, faLifeRing } from '@fortawesome/free-solid-svg-icons';
import UseCases from '../components/UseCases';
import HowItWorks from '../components/HowItWorks';
import MainFeatures from '../components/MainFeatures';
//import { faClock } from '@fortawesome/free-solid-svg-icons'
//import { faCogs } from '@fortawesome/free-solid-svg-icons'
//import { faCoins } from '@fortawesome/free-solid-svg-icons'

const Index = () => {
  const [usedCharacters, setUsedCharacters] = useState(256);
  const [showOverlay, setShowOverlay] = useState(false);
  const playVideoRef = useRef(null);
  const [formStatus, setFormStatus] = useState({
    name: {
      error: false,
      value: ''
    },
    email: {
      error: false,
      value: ''
    },
    phone: {
      error: false,
      value: ''
    },
    message: {
      error: false,
      value: ''
    }

  });

  const [successMessage, setSuccessMessage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const inputStyle = "border-4 hover:bg-yellow-200 my-2 p-2 focus:outline-none focus:ring focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-grey-300 rounded";
  const inputStyleErr = "border-4 hover:bg-yellow-200 my-2 p-2 focus:outline-none focus:ring focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-red-300 rounded";
  // const formStatus = {
  //   firstname: {
  //     error:false,
  //     value: ''
  //   },
  //   lastname: {
  //     error:false,
  //     value: ''
  //   },
  //   company: {
  //     error:false,
  //     value: ''
  //   },
  //   email: {
  //     error:false,
  //     value: ''
  //   },
  //   streetaddress: {
  //     error:false,
  //     value: ''
  //   },
  //   city: {
  //     error:false,
  //     value: ''
  //   },
  //   postcode: {
  //     error:false,
  //     value: ''
  //   }
  // };

  // const closeErrorOverlay = () => {
  //   setError({
  //     status: false,
  //     message: ''
  //   });
  // }

  const handleFormChange = ({ target }) => {
    let tempForm = formStatus;
    tempForm[target.name].value = target.value;
    tempForm[target.name].error = false;
    if (!target.value) {
      tempForm[target.name].error = true;
    }
    if (target.name === 'message') {
      setUsedCharacters(256 - target.value.length);
    }
    setFormStatus(tempForm);
    //console.log(formStatus);
  }

  const [error, setError] = useState({
    status: false,
    message: ''
  });

  const closeOverlay = () => {
    //console.log('closeOverlay function!');
    setShowOverlay(false);
    //console.log(`ShowOverlay is ${showOverlay}`);
  }

  const errorOverlay = (
    <MessageOverlay type="error" text={error.message} close={closeOverlay} />
  )

  const successOverlay = (
    <MessageOverlay type="success" text={successMessage} close={closeOverlay} />
  )

  const handleSubmit = (event) => {

    typeof window !== "undefined" && window.gtag && window.gtag('event', 'conversion', { 'send_to': 'AW-1061344408/pCV7CJiHhsMDEJipi_oD' });
    event.preventDefault();
    // let formObj = {
    //   firstname: formStatus.firstname.value,
    //   lastname: formStatus.lastname.value,
    //   streetaddress: formStatus.streetaddress.value,
    //   city: formStatus.city.value,
    //   postcode: formStatus.postcode.value,
    //   company: formStatus.company.value,
    //   email: formStatus.email.value
    // }

    let formObj = {
      name: formStatus.name.value,
      email: formStatus.email.value,
      phone: formStatus.phone.value,
      message: formStatus.message.value
    }
    for (const val of Object.keys(formObj)) {
      if (val !== 'phone' && !formObj[val]) {
        //console.log(val, 'hello');
        formStatus[val].error = true;
        setFormStatus(formStatus);
        //console.log(formStatus);
        setError({
          status: true,
          message: `form incomplete, missing ${val}`
        });
        setShowOverlay(true);
        return;
      }
    }
    // formObj.append('firstname', formStatus.firstname.value);
    // formObj.append('lastname', formStatus.lastname.value);
    // formObj.append('streetaddress', formStatus.streetaddress.value);
    // formObj.append('city', formStatus.city.value);
    // formObj.append('postcode', formStatus.postcode.value);
    // formObj.append('company', formStatus.company.value);
    // formObj.append('email', formStatus.email.value);
    const options = {
      method: 'POST',
      body: JSON.stringify(formObj),
      mode: 'cors',
      headers: { 'content-type': 'application/json' },
      redirect: 'follow',
    };
    fetch('https://api.punch-in.co.uk/contact', options)
      .then(res => {
        //console.log(res);
        if (res.status > 199 && res.status < 300) {
          // Login successful, hide login
          setSuccessMessage("Your message has been forwarded. We will get back to you as soon as possible.");
          setShowOverlay(true);
          return res.json();
        } else {
          //console.log('we are in the else');
          //console.log(res);
          setError({
            status: true,
            message: 'The server did not respond in an expected way. Please try again or contact support.'
          });
          return null;
        }
      })
      .then(response => {
        /// this is the userDetails data
        if (response) {
          //console.log(response);
        }

      }).catch(err => {
        //console.log(err, typeof err, Object.keys(err));
        setError({
          status: true,
          message: 'We could not reach the server, check your internet connection and try again.'
        });
        setShowOverlay(true);
        return null;
      })
  }

  const title = 'Face recognition based time tracking';
  let description = 'Face recognition based clocking and time management solution';
  description += ' tailored for small and medium businesses. If you need and easy to implement';
  description += ' solution, on a budget, with no lockups, then talk to us today!';
  const image = '/logo.svg';
  // prod
  const currentPrices = {
    light: 'price_1ODOhZJFfOivkS1y5QfY1ms8',
    medium: 'price_1ODOj5JFfOivkS1yBAGcJrv9',
    large: 'price_1ODOjkJFfOivkS1y9AgTDCTg',
    giant: 'price_1ODOkVJFfOivkS1y6TVCa8kq' // price_1KiITWJFfOivkS1yFTjesk5x
  }

  const playVideo = () => {
    setShowVideo(!showVideo);
    console.log(playVideoRef);
    ///// finish show/hide
    playVideoRef.current.play();
  }

  // test
  // const currentPrices = {
  //   light: 'price_1K2bRBJFfOivkS1yzkH4ZuRt',
  //   medium: 'price_1K2bSfJFfOivkS1yH6JFTGwp',
  //   large: 'price_1KeL2lJFfOivkS1yASKbdL0U',
  //   giant: 'price_1KiITWJFfOivkS1yFTjesk5x'
  // }

  return (
    <Layout title={title} description={description} image={image} article={false}>
      {showOverlay && error.status && errorOverlay}
      {showOverlay && !error.status && successOverlay}
      <section className="sm:pt-20 md:pt-30">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-2/5">
            <Carousel />
            <div className="mt-8 md:mt-12">
              <AnchorLink className={`
                btn
                py-4 px-12
                bg-primary
                hover:bg-primary-darker
                rounded-lg
                font-bold
                text-white
                `} href="#pricing" title="Start your 1-month free trial">Get started now</AnchorLink>
            </div>
            <p className="mt-4 text-black-600">Try it one month for <strong>free</strong>!</p>
          </div>
          <div className="lg:w-3/5 px-8">
            {!showVideo && (<h3 className="text-md lg:text-lg font-semibold text-blue-300 italic mb-3 text-right mx-8">watch our intro video:</h3>)}
            {!showVideo && (<a href="#" id="videoLink" onClick={playVideo}><img src={bannerImage} alt="Any Android hardware can be used" /></a>)}
            {showVideo ? (
              <video id="videoContainer" ref={playVideoRef} poster={bannerImage} >
                <source src={PresentationVideo} type="video/mp4" />
              </video>
            ) : (
              <video className="hidden" id="videoContainer" ref={playVideoRef} poster={bannerImage} controls>
                <source src={PresentationVideo} type="video/mp4" />
              </video>
            )}

          </div>

        </div>
        <div className="container mx-auto px-8">
          <UseCases />
        </div>

      </section>
      <MainFeatures />
      <HowItWorks />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight text-blue-700">No expensive hardware</h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              You don't need to buy expensive hardware to start using biometric access control.
              Place a tablet with <strong>PunchIn</strong> application on the reception
              desk and you are ready to accept biometric data.
            </p>
          </div>
        }
        secondarySlot={<img src={anyHardware} alt="Any Android hardware can be used" />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight text-blue-700">
              Choose the right solution
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Define your needs. Whether you need a simple time tracking app for
              your contractors or a full blown gated access, we can help. <br />
              Give us a call at <a type="tel" href="tel:+44-1604-664-179">+44-1604-664-179</a> and book an appointment
              to go through your business case.
            </p>
          </div>
        }
        secondarySlot={<img src={rightSolution} alt="You can easily integrate the time tracking data with your HR tool" />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight text-blue-700">
              Expenses optimization
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Start saving from day one with the right time and access tracking
              tool. Easy to configure and ready to use in less than 5 minutes.
            </p>
          </div>
        }
        secondarySlot={<img src={saveCosts} alt="Enhance your security and finances by using a discreet Face recognition tool" />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight text-blue-700">
              Reporting
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Easy to generate <strong>reports</strong> to quickly understand
              what are the key indicators in the company. These include
              mean hours worked per week and per month, average entry/exit time,
              percentage of delayed entries/early leaves.
            </p>
          </div>
        }
        secondarySlot={<SvgCharts />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight text-blue-700">
              HR Management
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Ease of management for employees and contractors with our continuously updated HR management tool.
              Manage contracts, tasks, leave requests, and more; access dashboards to se your work force cash flow, worked hours per project,
              per department, and more.
            </p>
          </div>
        }
        secondarySlot={<img src={rightSolution} alt="HR Desk" />}
      />
      <section id="pricing" className="py-20 lg:pb-40 lg:pt-48">
        <Payments currentPrices={currentPrices} />
        <div className="container mx-auto text-left">
          <h2 className="text-3xl lg:text-4xl font-semibold text-blue-700 my-10 mb-5">Extras:</h2>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="table-auto w-full text-sm text-left text-blue-900 dark:text-gray-400">
              <thead className="text-xs text-white uppercase bg-blue-700 dark:bg-blue-900 dark:text-slate-50">
                <tr>
                  <th className="px-6 py-3">Feature</th>
                  <th className="px-6 py-3">Cost</th>
                  <th className="px-6 py-3">Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td className="px-6 py-4">Integration with <a href="https://quickbooks.intuit.com/uk/">QuickBooks</a>  to keep Employees in sync</td>
                  <td className="px-6 py-4">&pound;6.95 per tier</td>
                  <td className="px-6 py-4">monthly </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td className="px-6 py-4">Take a picture at every clock in and clock out</td>
                  <td className="px-6 py-4">&pound;6 per tier</td>
                  <td className="px-6 py-4">The light package will cost &pound;6, the medium &pound;12, etc... </td>
                </tr>
              </tbody>
            </table>
            <div className="m-5">
              <p><strong>Note</strong>: please
                <AnchorLink href="#pricing" title="Contact support"> contact </AnchorLink>
                our support team to activate your extras.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="testimonials" className="py-20 lg:py-40">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {customerData.map(customer => (
              <div key={customer.customerName} className="flex-1 px-3">
                <CustomerCard customer={customer} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="docs" className="py-20 lg:py-40">
        <div className="container mx-auto text-center">
          <h2 className="text-xl lg:text-3xl font-semibold text-blue-700"><FontAwesomeIcon icon={faBook} /> Documentation</h2>
        </div>
        <div className="container mx-auto text-left">
          <p className="pt-10">
            Punch-in documentation pages will guide you through the setup and usage of your environment. The toolsed comprises two parts: the <a target="_blank" rel="noreferrer" href="https://repository.punch-in.co.uk/punch-in_latest.apk" title="Click to download Punch-in Android application for your tablet or phone">Android application</a>.
          </p>
          <ul className="pt-5  md:text-xl">
            <li className="pl-10 py-3"><a href="/documentation-mobile-device" className="hover:text-blue-700"><FontAwesomeIcon icon={faBook} /> Mobile device application</a> </li>
            <li className="pl-10 py-3"><a href="/documentation-backend" className="hover:text-blue-700"><FontAwesomeIcon icon={faBook} /> Backend application</a> </li>
            <li className="pl-10 py-3"><a href="/documentation-employee-portal" className="hover:text-blue-700"><FontAwesomeIcon icon={faBook} /> Employee portal</a> </li>
            <li></li>
          </ul>
          <h2 className="text-xl lg:text-xl font-semibold text-blue-700 pt-10">Quick setup</h2>
          <ul className="pt-2">
            <li className="pl-10"><FontAwesomeIcon icon={faHandPointRight} className="text-blue-700 pr-2 text-xl" />Download the <a className="hover:text-blue-700" target="_blank" rel="noreferrer" href="https://repository.punch-in.co.uk/punch-in_latest.apk" title="Download Punch-in Android application for your tablet or phone">Android application</a></li>
            <li className="pl-10"><FontAwesomeIcon icon={faHandPointRight} className="text-blue-700 pr-2 text-xl" />Configure it by using the QR-code you have been given or manually, by entering credentials.</li>
            <li className="pl-10"><FontAwesomeIcon icon={faHandPointRight} className="text-blue-700 pr-2 text-xl" />Start adding users under the Users menu. You need a photograph for each user.
              <span className="font-bold"> Tips:</span></li> to get the best results, make sure there is enough light, the face must be centered and showing hair and ears, no glasses, scarfs or hats.
            <li></li>
          </ul>
        </div>
      </section>
      <section id="contact" className={successMessage ? 'hidden' : 'py-20 lg:pb-40 lg:pt-48'}>
        <div className="container mx-auto text-center">
          <h2 className="text-xl lg:text-3xl font-semibold text-blue-700"><FontAwesomeIcon icon={faEnvelope} /> Contact us</h2>
        </div>
        <div className="container mx-auto text-left">
          <p className="mt-8 text-xl font-light">
            Leave us a note or give us a <a className="tracking-normal whitespace-nowrap" type="tel" href="tel:+44-1604-664-179" title="Call us for more information"><FontAwesomeIcon icon={faPhone} /> call</a> and we will get in touch ASAP:
          </p>
          <form onSubmit={event => { handleSubmit(event); }} method="POST">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 md:col-span-3">
                    <label htmlFor="name" className="mt-5 block text-sm font-medium text-gray-700">
                      Name: <span className="text-red-600">*</span>
                    </label>
                    <input onChange={handleFormChange} className={formStatus.name.error ? inputStyleErr : inputStyle} type="text" name="name" id="name" placeholder="John Doe" />
                  </div>
                  <div className="col-span-6 md:col-span-3">
                    <label htmlFor="phone" className="mt-5 block text-sm font-medium text-gray-700">
                      Phone:
                      <input onChange={handleFormChange} className={inputStyle} type="tel"
                        name="phone" autoComplete="tel" id="phone" placeholder="078912345" />
                    </label>
                  </div>
                  <div className="col-span-6 md:col-span-3">
                    <label htmlFor="email" className="mt-5 block text-sm font-medium text-gray-700">
                      Email: <span className="text-red-600">*</span>
                      <input onChange={handleFormChange} className={formStatus.email.error ? inputStyleErr : inputStyle} type="email" name="email" autoComplete="email" id="email" placeholder="john@example.com" />
                    </label>
                  </div>
                  <div className="col-span-6 md:col-span-3">
                    <label htmlFor="message" className="mt-5 block text-sm font-medium text-gray-700">
                      Message{usedCharacters < 256 ? `, ${usedCharacters} characters left` : ''}: <span className="text-red-600">*</span>
                    </label>
                    <textarea onChange={handleFormChange} id="message" name="message" maxLength="256" className={formStatus.message.error ? inputStyleErr : inputStyle} rows="3" cols="33" autoComplete="off" placeholder="Enter your text here...">
                    </textarea>
                  </div>
                  <div className="col-span-6 md:col-span-3">
                    <button className="btn
                      py-4 px-12
                      bg-primary
                      hover:bg-primary-darker
                      rounded
                      text-white" type="submit" value="Submit"><FontAwesomeIcon icon={faEnvelope} />  Send request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section id="support" className="py-20 lg:pb-40 lg:pt-48">
        <div className="container mx-auto text-center">

          <h2 className=" text-xl lg:text-3xl font-semibold text-blue-700"><FontAwesomeIcon icon={faLifeRing} /> Help</h2>
        </div>
        <div className="container mx-auto my-10 py-4 bg-gray-200 rounded-lg text-center">
          <h4>Contact us</h4>
          <h5 className="py-2" ><a type="email" href="mailto:support@mail.punch-in.co.uk" title="Write to support"><FontAwesomeIcon icon={faEnvelope} /> support@mail.punch-in.co.uk</a></h5>
          <h5 className="py-2" ><a type="tel" href="tel:+44-1604-664-179" title="Call support"> <FontAwesomeIcon icon={faPhone} />+44 1679 5611</a></h5>
        </div>
        <div className="container mx-auto text-justify">
          <p className="m-8 text-xl font-light">
            We are here to <strong>help</strong>! You can <a type="email" href="mailto:support@mail.punch-in.co.uk" title="Write to support"><FontAwesomeIcon icon={faEnvelope} />write </a> to us or
            <a type="tel" href="tel:+44-1604-664-179" title="Call support"> <FontAwesomeIcon icon={faPhone} /> call us </a>
            and we will get back to you via e-mail.
            Our first technical contact aims to be no longer than 24 hours during busy times.
            <strong> Premium support </strong> customers are entitled to speak to an engineer/developer
            over the phone for a quicker problem resolution.
          </p>
          <p className="m-8 text-xl font-light">
            <strong>Premium support</strong> is available from £5.99 per month.
            Talk to one of our sales representative via <a type="tel" href="tel:+44-1604-664-179" title="Call sales"> <FontAwesomeIcon icon={faPhone} /> phone</a> or <a type="email" href="mailto:sales@mail.punch-in.co.uk" title="Contact sales team"><FontAwesomeIcon icon={faEnvelope} /> email</a>
          </p>
        </div>
      </section>
      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-3xl md:text-5xl font-semibold"><FontAwesomeIcon icon={faFighterJet} /> Ramp up your attendance management</h3>
        <p className="mt-8 text-xl font-light">
          Save money by correctly managing your staff on site and off site. Works
          in any conditions, as long as you have enough battery on your device
        </p>
        <p className="mt-8">
          <AnchorLink className={`
            btn
            py-4 px-12
            bg-primary
            hover:bg-primary-darker
            rounded-lg
            font-bold
            text-white
        `} href="#pricing" title="Start your 1-month free trial">Get started now!</AnchorLink>
        </p>
      </section>
    </Layout>
  );
}
export default Index;
