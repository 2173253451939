import React, { useState } from "react";


function PhotoSlider({ data }) {
  const [isOpen, setIsOpen] = useState({ img: 0, state: false });
  return (
    <div className="flex flex-row mx-1 lg:-mx-4 justify-around my-8">
      {
        data.map((item, index) => {
          return (
            <div key={index} className="rounded-lg shadow-md border border-solid border-gray-200">
              <img className="shadow-md" src={item.image} alt={item.alt}
                style={{ height: '3em', cursor: 'pointer' }} onClick={() => setIsOpen({ img: index, state: true })} />
            </div>
          )
        })
      }
      {isOpen.state && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}>
          <div style={{ position: 'relative', width: 'fit-content', margin: '2em' }}>
            <button onClick={() => {
              setIsOpen({ img: (isOpen.img > 0 ? isOpen.img - 1 : data.length - 1), state: true });
            }}
              style={{ position: 'absolute', left: '42px', top: '50%', transform: 'translateY(-50%)', zIndex: 1001 }}
              className="rounded-full h-8 w-8 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 flex items-center justify-center">
              {'<'}
            </button>
            { data[isOpen.img].isVideo ? 
                ( <video id="shiftsVideo" controls>
                  <source src={data[isOpen.img].video} type="video/mp4" />
                </video> ) :
                (<img src={data[isOpen.img].image} alt={data[isOpen.img].alt}
              style={{ height: '650px', borderRadius: '1em', margin: '2em', border: '3em solid #EFEFEF', boder: '1em solid #EFEFEF' }} />) 
            }
            <button onClick={() => {
              setIsOpen({ img: (isOpen.img < data.length - 1 ? isOpen.img + 1 : 0), state: true });
            }}
              style={{ position: 'absolute', right: '42px', top: '50%', transform: 'translateY(-50%)', zIndex: 1001 }}
              className="rounded-full h-8 w-8 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 flex items-center justify-center">
              {'>'}
            </button>
            <button type="button" onClick={() => setIsOpen({ img: 0, state: false })} style={{
              position: 'fixed',
              top: 10,
              right: 10,
            }} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PhotoSlider;