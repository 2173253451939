import { React, useState } from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock, faKey, faMoneyCheckAlt, faTools, faUserTie, faTable, faHeadset, faLock, faClock } from '@fortawesome/free-solid-svg-icons';
import Card from "./Card";
import PhotoSlider from "./PhotoSlider";
import dashboardImg from '../assets/images/punch-in-employee-portal-dashboard.jpg';
import timeOffImg from '../assets/images/punch-in-employee-portal-time-off.jpg';
import tasksImg from '../assets/images/punch-in-employee-portal-tasks.jpg';
import dashboardHr from '../assets/images/backend-hr-dashboard.png';
import emplDetails from '../assets/images/backend-hr-employee-details.png';
import newEmpl from '../assets/images/backend-hr-new-employee.png';
import LearnMore from "./LearnMore";
import shiftVideo from '../assets/media/shifts_creation.mp4';
import shiftVideoImage from '../assets/images/shifts.png';
import { faFly } from "@fortawesome/free-brands-svg-icons";

const CardTitle = ({ icon, text }) => (
    <h3 className="font-semibold text-xl text-blue-700">
        <FontAwesomeIcon icon={icon} />&nbsp;{text}
    </h3>
);

const MainFeatures = () => {

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const shiftsSlider = [
        {
            image: shiftVideoImage,
            video: shiftVideo,
            alt: 'How to create a shift in Punch-in',
            isVideo: true
        },

    ]

    const employeePhotoSlider = [
        {
            image: dashboardImg,
            alt: 'Employee portal dashboard'
        },
        {
            image: tasksImg,
            alt: 'Employee portal tasks management'
        },
        {
            image: timeOffImg,
            alt: 'Employee portal vacation request module'
        },
    ]

    const hrPhotoSlider = [
        {
            image: dashboardHr,
            alt: 'HR backend dashboard'
        },
        {
            image: emplDetails,
            alt: 'HR backend employee details'
        },
        {
            image: newEmpl,
            alt: 'HR backend new employee'
        },
    ]
    return (
        <section id="features" className="py-20 lg:pb-40 lg:pt-48">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl lg:text-5xl font-semibold text-blue-700">Main Features</h2>
                <div className="flex flex-col md:flex-row sm:mx-3 mt-12">
                    <div className="flex-1 px-3">
                        <Card className="mb-8">
                            <CardTitle icon={faUserClock} text="Time tracking" />
                            <p className="mt-4">
                                <em>Punch In</em> helps you quickly and easly set up an attendance system based on
                                face recognition. No phisical contact required and proven technology behind the scenes. <br />
                            </p>
                            <LearnMore link="/time-tracking" />
                        </Card>
                    </div>
                    <div className="flex-1 px-3">
                        <Card className="mb-8">
                        <CardTitle icon={faKey} text="Access control*" />
                            <p className="mt-4">
                                Improve the security of your office building
                                or restrict access to parts of the office space.
                            </p>
                            <p><em>*Needs additional/bespoke configuration</em></p>
                        </Card>
                    </div>
                    <div className="flex-1 px-3">
                        {/*<Card className="mb-8">
                <p className="font-semibold text-xl">Emergency</p>
                <p className="mt-4">
                  Access real time data about who is on your premises.
                  This means you have an accurate register in the event
                  of an evacuation, ensuring the safety of everyone in the building.
                </p>
              </Card> */}
                        <Card className="mb-8">
                        <CardTitle icon={faMoneyCheckAlt} text="Payroll" />

                            <p className="mt-4">
                                Manage your employees' salary and payslips with ease. Thanks to our partners <strong>Shape Payroll</strong>, we offer
                                a complete payroll solution which will deal with all the complexities and compliance requirements
                                of your company.
                            </p>
                            <LearnMore link="/payroll" />
                        </Card>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row sm:mx-3 mt-12">
                    <div className="flex-1 px-3">
                        <Card className="mb-8">
                            <CardTitle icon={faUserTie} text="Employees portal" />

                            <p className="mt-4">
                                Employees, contractors or permanent employees can access their own attendance data. They can manage
                                their vacations, sick days, and other events. They can visualize tasks and projects assigned to them, as well as their
                                performance reviews.
                            </p>
                            <PhotoSlider data={employeePhotoSlider} settings={sliderSettings} />
                            <p className="italic text-sm">
                                Above screenshots from the <strong>Employee portal</strong>
                            </p>

                        </Card>
                    </div>
                    <div className="flex-1 px-3">
                        <Card className="mb-8">
                            <CardTitle icon={faUserTie} text="HR system" />
                            <p className="mt-4">
                                You can opt to use <em>Punch-in</em> as a central HR system for your company. We offer contract management, time off,
                                payroll and more. Get in touch to view a demo. We are also happy to help you integrate <em>Punch-in</em>
                                with your existing HR system via event-driven integrations.
                            </p>
                            <PhotoSlider data={hrPhotoSlider} settings={sliderSettings} />
                            <p className="italic text-sm">
                                The screenshots are from the <strong>HR Management</strong> module.
                            </p>
                        </Card>
                    </div>
                    <div className="flex-1 px-3">
                        <Card className="mb-8">
                            <CardTitle icon={faTable} text="Shifts management" />
                            <p className="mt-4">
                                <em>Punch-in</em> is a great tool for managing shifts and time off. We offer granular control over complex shift
                                planning, which will help organize your employees' time and make sure their shifts are as balanced as possible 
                                and follow at best all of their time and contractual constraints.
                            </p>
                            <PhotoSlider data={shiftsSlider} />
                        </Card>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row sm:mx-3 mt-12">
                    <div className="flex-1 px-3">
                        <Card className="mb-8">
                            <CardTitle icon={faClock} text="Saves time" />
                            <p className="mt-4">
                                <em>Punch In</em> is designed around you, thus every step is taken to
                                save your time: from the reporting facilities, to the hardware maintenance
                                (virtually inexistent)
                            </p>
                        </Card>
                    </div>
                    <div className="flex-1 px-3">
                        <Card className="mb-8">
                            <CardTitle icon={faLock} text="Secure" />
                            <p className="mt-4">
                                Your data is kept in a secure database to which only you have access. No shared data exists
                                between environments. We take great attention to providing a working environment which complies
                                with the latest security standards. Our infrastructure resides in the cloud for added resiliency.
                            </p>
                            <LearnMore link="/security" />
                        </Card>
                    </div>
                    <div className="flex-1 px-3">
                        <Card className="mb-8">
                            <CardTitle icon={faFly} text="Easy to use" />
                            <p className="mt-4">
                                No complicated menu options, no difficult learning curve, no courses needed, no configuration required: your employees will be
                                ready to use the tool in a few hours. Also, we are always here to <AnchorLink href="#support" className="whitespace-nowrap hover:text-blue-200 tracking-normal" title="Our support team is ready to help you"> <FontAwesomeIcon icon={faHeadset} /> help</AnchorLink> if you need it!
                            </p>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainFeatures;